<template>
  <!-- 模版类型1 -->
  <template v-if="componentInfo && componentInfo.footerStyle === 1">
    <div class="footer-wrapper">
      <div class="footer-style1">
        <div class="copyright">
          <span class="mgr10">Copyright ©</span>{{ componentInfo.copyright }}
        </div>
        <div class="blogroll">
          <template v-for="item in componentInfo.customList">
            <span
              v-if="item.titleShow"
              :key="item.id"
              class="link_wrap"
            >
              <span
                v-if="!getLinkUrl(item.linkUrl)"
                class="link mgr10"
              >{{
                item.title
              }}</span>
              <a
                v-else
                :href="item.linkUrl"
                class="link mgr10"
                target="_blank"
              >
                {{ item.title }}
              </a>
            </span>
          </template>
        </div>
      </div>
    </div>
  </template>
  <!-- 模版类型2 -->
  <template v-if="componentInfo && componentInfo.footerStyle === 2">
    <div class="footer-wrapper">
      <div class="footer-style2">
        <template v-if="navList.length">
          <ul
            v-for="(list, index) in navList"
            :key="index"
            class="footer_nav"
          >
            <custom-link
              v-for="(item, j) in list"
              :key="j"
              :to="handleNavigate(item)"
              :target-type="item.targetType"
              :target-open-new-page="item.targetOpenNewPage"
              class="nav_item"
            >
              <li class="nav_item">
                <div class="nav_name">
                  {{ item.navName }}
                </div>
                <div
                  v-if="j !== list.length - 1"
                  class="border"
                />
              </li>
            </custom-link>
          </ul>
        </template>
        <div class="copyright">
          <span class="mgr10">Copyright ©</span>{{ componentInfo.copyright }}
        </div>
        <div class="blogroll">
          <template v-for="item in componentInfo.customList">
            <span
              v-if="item.titleShow"
              :key="item.id"
              class="link_wrap"
            >
              <span
                v-if="!getLinkUrl(item.linkUrl)"
                class="link mgr10"
              >{{ item.title  }}</span>
              <a
                v-else
                :href="item.linkUrl"
                class="link mgr10"
                target="_blank"
              >
                {{ item.title }}
              </a>
            </span>
          </template>
        </div>
      </div>
    </div>
  </template>
  <!-- 模版类型3 -->

  <template v-if="componentInfo && componentInfo.footerStyle === 3">
    <div class="footer-wrapper">
      <div class="footer-style3 w">
        <div class="footer-left">
          <div class="row">
            {{ componentInfo.contentOne }}
          </div>
          <div class="row">
            {{ componentInfo.contentTwo }}
          </div>
          <div class="row line" />
          <div class="row copyright">
            <span class="mgr10">Copyright ©</span>{{ componentInfo.copyright }}
          </div>
          <div class="row blogroll">
            <template v-for="item in componentInfo.customList">
              <span
                v-if="item.titleShow"
                :key="item.id"
                class="link_wrap"
              >
                <span
                  v-if="!getLinkUrl(item.linkUrl)"
                  class="link mgr10"
                >{{ item.title }}</span>
                <a
                  v-else
                  :href="item.linkUrl"
                  class="link mgr10"
                  target="_blank"
                >
                  {{ item.title }}
                </a>
              </span>
            </template>
          </div>
        </div>
        <div class="footer-right">
          <client-only>
            <el-popover
              v-if="componentInfo.showWechat"
              placement="top"
              trigger="hover"
            >
              <template #reference>
                <img
                  class="pointer mgr20"
                  src="@/assets/images/wechat.png"
                  alt=""
                >
                <!-- <i class="font32 iconfont icon-weixin"></i> -->
              </template>
              <img
                class="popover_img"
                :src="componentInfo.wechatUrl"
                alt=""
              >
            </el-popover>
          </client-only>
          <client-only>
            <el-popover
              v-if="componentInfo.showMiniApp"
              placement="top"
              trigger="hover"
            >
              <template #reference>
                <img
                  class="pointer mgr20"
                  src="@/assets/images/miniApp.png"
                  alt=""
                >
                <!-- <i class="font32 mgl20 iconfont icon-xiaochengxu"></i> -->
              </template>
              <img
                class="popover_img"
                :src="componentInfo.miniAppUrl"
                alt=""
              >
            </el-popover>
          </client-only>
          <a
            v-if="componentInfo.showWeibo"
            target="_blank"
            :href="componentInfo.weiboUrl"
          >
            <img
              class="pointer mgr20"
              src="@/assets/images/weibo.png"
              alt=""
            >
          </a>

          <!-- <i
            v-if="componentInfo.showWeibo"
            class="font32 mgl20 iconfont icon-weibo"
          ></i> -->
          <a
            v-if="componentInfo.showQq"
            target="_blank"
            :href="'https://wpa.qq.com/msgrd?y=3&uin='+ componentInfo.qqUrl + '&site=qq&menu=yes'"
          >
            <img
              class="pointer"
              src="@/assets/images/QQ.png"
              alt=""
            >
          </a>
          <!-- <i
            v-if="componentInfo.showQq"
            class="font32 mgl20 iconfont icon-QQ"
          ></i> -->
        </div>
      </div>
    </div>
  </template>
</template>
<script setup>
import { getFooter } from '@/api/layouts'
import { validateURL } from '@/utils/validate'
import { useLayoutStore } from '@/stores/layout'
const { setFooterType } = useLayoutStore()

const getLinkUrl = (url) => {
  return validateURL(url) ? url : ''
}
const { data: componentInfo } = await useAsyncData('footer', async (ctx) => {
  return getFooter()
})
const navList = ref([])
if (componentInfo.value && componentInfo.value.navList && componentInfo.value.navList.length) {
  let list = componentInfo.value.navList.concat([])
  if (list && list.length) {
    if (list.length <= 6) {
      list = [list]
    }
    if (list.length > 6 &&  list.length < 15 ) {
      let arr = []
      let i = Math.floor(list.length / 2)
      arr.push(list.slice(0, i))
      arr.push(list.slice(i, list.length))
      list = arr
    }
    if (list.length >= 15 ) {
      let arr = []
      let i = Math.floor(list.length / 3)
      arr.push(list.slice(0, i))
      arr.push(list.slice(i, i + i))
      arr.push(list.slice(i + i, list.length))
      list = arr
    }
    navList.value = list
  }
}
onMounted(() => {
  if (componentInfo.value) {
    setFooterType(componentInfo.value.footerStyle)
  }
})
</script>
<style lang="scss">
.footer-wrapper {
  width: 100%;
  background: #16223d;
  color: #becadb;
  line-height: 32px;
  .footer-style1 {
    vertical-align: center;
    width: 100%;
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .footer-style2 {
    vertical-align: center;
    width: 100%;
    height: 180px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .footer_nav {
      display: flex;
    }
    .copyright {
      margin-top: 10px;
    }
    .nav_item {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      .nav_name {
        color: #becadb;
        padding: 2px 0;
        min-width: 140px;
        text-align: center;
        white-space: nowrap;
      }
      .border {
        border: none;
        background: #becadb;
        display: inline-block;
        height: 10px;
        margin: 0 30px;
        width: 1px;
      }
    }
  }
  .footer-style3 {
    display: flex;
    justify-content: space-between;
    height: 260px;
    .footer-left {
      padding-top: 30px;
      height: 100%;
      .line {
        margin: 30px 0;
        width: 20px;
        height: 3px;
        background: #becadb;
      }
    }
    .footer-right {
      display: flex;
      align-items: center;
      height: 100%;
      .popover_img {
        width: 100px;
        height: 100px;
        object-fit: cover;
      }
    }
  }
}
.link {
  color: #becadb;
}
</style>
